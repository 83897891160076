import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/tutorial',
    component: () => import('@/views/tutorial'),
    hidden: true
  },
  {
    path: '/',
    redirect: '/live/serial'
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  // {
  //   path: '/live',
  //   name: 'live',
  //   component: Layout,
  //   redirect: '/live/general',
  //   meta: { title: '直播', icon: 'menu-live' },
  //   children: [
  //     {
  //       path: 'serial',
  //       name: 'liveSerial',
  //       component: () => import('@/views/pages/live/serial'),
  //       meta: { title: '系列邀约', icon: 'menu-subitem' }
  //     },
  //     /// 微信-系列邀约
  //      {
  //       path: 'wx/control',
  //       name: 'wxLiveControl',
  //       hidden: true,
  //       component: () => import('@/views/pages/live/serial/Wx/control'),
  //       meta: { title: '控制台', icon: 'menu-subitem' }
  //     },
  //     /// 抖音-系列邀约
  //      {
  //       path: 'dy/control',
  //       name: 'dyLiveControl',
  //       hidden: true,
  //       component: () => import('@/views/pages/live/serial/Dy/control'),
  //       meta: { title: '控制台', icon: 'menu-subitem' }
  //     },
  //     /// 京东-系列邀约
  //     {
  //       path: 'jd/control',
  //       name: 'jdLiveControl',
  //       hidden: true,
  //       component: () => import('@/views/pages/live/serial/Jd/control'),
  //       meta: { title: '控制台', icon: 'menu-subitem' }
  //     },
  //     /////////////////////////
  //     {
  //       path: 'appoint',
  //       name: 'liveAppoint',
  //       component: () => import('@/views/pages/live/appoint'),
  //       meta: { title: '系列预约', icon: 'menu-subitem' }
  //     }

  //   ]
  // }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
